import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../axios";

function AssetImageUpload({ onUpload }) {
  // Accept onUpload as a prop
  const [file, setFile] = useState(null);
  const [assetImageURL, setAssetImageURL] = useState("");

  const handleAssetImageUpload = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      console.log("No files selected");
      return;
    }

    console.log("Files selected:", files.length);

    const formData = new FormData();

    formData.append("image", files[0]);

    try {
      const response = await axios.post("/blog/upload-asset", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Server response:", response.data);

      if (response.data.success) {
        console.log(response.data.result);

        const uploadedURL = response.data.result;
        if (uploadedURL) toast.success("Asset image uploaded successfully!");
        setAssetImageURL(uploadedURL);

        // Pass the URL to the parent component
        if (onUpload) {
          onUpload(uploadedURL);
        }
      } else {
        toast.error(response.data.message || "Upload failed");
      }
    } catch (error) {
      console.error("Error uploading the asset image:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      toast.error("Failed to upload asset image. Please try again later.");
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white shadow-md rounded-lg">
      <ToastContainer />
      <h2 className="text-xl font-semibold mb-4">Upload Asset Image</h2>
      <div className="mb-4">
        <label
          htmlFor="assetImage"
          className="block text-sm font-medium text-gray-700"
        >
          Asset Image Upload
        </label>
        <input
          type="file"
          name="files"
          id="assetImage"
          onChange={handleAssetImageUpload}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          multiple
        />
      </div>
      {assetImageURL && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Asset Image URL
          </label>
          <input
            type="text"
            readOnly
            value={assetImageURL}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <button
            onClick={() => navigator.clipboard.writeText(assetImageURL)}
            className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Copy URL
          </button>
        </div>
      )}
    </div>
  );
}

export default AssetImageUpload;
