import React from 'react'
import AdminLogin from '../components/AdminLogin/AdminLogin'

function AdminLoginPage() {
  return (
    <div>
      <AdminLogin/>
    </div>
  )
}

export default AdminLoginPage
