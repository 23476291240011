import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from "./axios";
import AdminRoutes from "./Routes/AdminRoutes";
import UserRoutes from "./Routes/UserRoutes";

function App() {
  axios.defaults.withCredentials = true;
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<Navigate to="/dashboard" />} path={"/"} />
          <Route element={<AdminRoutes />} path={"/*"} />
          <Route element={<UserRoutes />} path={"/*"} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
