import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../axios";
import { Route, Routes } from "react-router-dom";
import AdminDashboardPage from "../Pages/AdminDashboardPage";
import AdminLoginPage from "../Pages/AdminLoginPage";
import UnprotectedRoute from "../Pages/UnProtectedRoute";
import PrivateRoute from "../Pages/PrivateRoute";
import AddArt from "../components/AddArtMain/AddArt";
import AddBlog from "../components/AddBlogMain/AddBlog";

function AdminRoutes() {
  const { refresh, superAdmin } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    setCheckingAuth(true);

    axios
      .get("/admin/profile")
      .then((response) => {
        console.log("ADMIN: ", response.data);
        dispatch({
          type: "accesToken",
          payload: { success: true, results: response.data.results },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setCheckingAuth(false));
  }, [refresh, dispatch]);

  console.log(superAdmin, "superAdmin");

  if (checkingAuth) {
    return <p>Loading...</p>;
  }
  return (
    <div>
      <Routes>
        <Route path="" element={<UnprotectedRoute />}>
          <Route path="/login" element={<AdminLoginPage />} />
        </Route>

        <Route path="" element={<PrivateRoute />}>
          <Route path="/dashboard" element={<AdminDashboardPage />} />
          <Route path="/blog-management" element={<AddBlog />} />
          <Route path="/art-lab" element={<AddArt />} />
        </Route>
      </Routes>
    </div>
  );
}

export default AdminRoutes;
