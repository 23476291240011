import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import AddBlog from "./AddBlog";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"; // Import the wrapper
import JoditEditor from "jodit-react";
import AssetImageUpload from "./AssetImageUpload";

const MySwal = withReactContent(Swal); // Create a Swal instance with ReactContent

function AddBlogForm() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    shortTitle: "",
    metaTitle: "",
    metaDescription: "",
    metaTag: "",
    slug: " ",
    jsonContent: "",
  });
  const [coverImage, setCoverImage] = useState(null);
  const [closeForm, setCloseForm] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const contentFieldChanged = (data) => {
    setEditorContent(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const {
      title,
      shortTitle,
      metaTitle,
      metaDescription,
      metaTag,
      slug,
      jsonContent,
    } = formData;

    if (
      !title ||
      !shortTitle ||
      !metaTitle ||
      !metaDescription ||
      !metaTag ||
      !slug ||
      !jsonContent ||
      !editorContent
    ) {
      toast.error("All fields are mandatory");
      setIsSubmitting(false);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("title", title);
    formDataToSend.append("shortTitle", shortTitle);
    formDataToSend.append("metaTitle", metaTitle);
    formDataToSend.append("metaDescription", metaDescription);
    formDataToSend.append("metaTags", metaTag);
    formDataToSend.append("slug", slug.toLocaleLowerCase());
    formDataToSend.append("jsonContent", jsonContent);
    formDataToSend.append("editorContent", editorContent);

    if (coverImage) {
      formDataToSend.append("image", coverImage);
    }

    try {
      const response = await axios.post("/blog", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Server response:", response.data);
      
      if (response.data.success) {
        toast.success("Blog has been added successfully");
        setCloseForm(true);
        navigate("/blog-management");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("Failed to add blog. Please try again later.");
    } finally {
      setIsSubmitting(false); // Stop submission
    }
  };

  const handleAssetImageUpload = (imageUrl) => {
    console.log("Received image URL:", imageUrl);
    // You can set it in the form state or do something else with it
  };

  const showAssetImageUploadModal = () => {
    MySwal.fire({
      title: "Upload Asset Image",
      html: <AssetImageUpload onUpload={handleAssetImageUpload} />,
      showConfirmButton: false, // You can add a confirm button if needed
      showCloseButton: true,
      allowOutsideClick: false,
    });
  };

  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  if (closeForm) {
    return <AddBlog />;
  }

  return (
    <>
      <ToastContainer />
      {closeForm ? (
        <AddBlog />
      ) : (
        <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
          <h2 className="text-2xl font-semibold mb-6">Add Blog Post</h2>
          <div className="flex justify-end">
            <button
              onClick={closeFormAndOpenPage}
              className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span className="ml-2">Back</span>
            </button>
          </div>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            {/* Title */}
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Short Title */}
            <div className="mb-4">
              <label
                htmlFor="shortTitle"
                className="block text-sm font-medium text-gray-700"
              >
                Short Title
              </label>
              <input
                type="text"
                id="shortTitle"
                name="shortTitle"
                value={formData.shortTitle}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Meta Title */}
            <div className="mb-4">
              <label
                htmlFor="metaTitle"
                className="block text-sm font-medium text-gray-700"
              >
                Meta Title
              </label>
              <input
                type="text"
                id="metaTitle"
                name="metaTitle"
                value={formData.metaTitle}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Meta Description */}
            <div className="mb-4">
              <label
                htmlFor="metaDescription"
                className="block text-sm font-medium text-gray-700"
              >
                Meta Description
              </label>
              <textarea
                id="metaDescription"
                name="metaDescription"
                value={formData.metaDescription}
                onChange={handleInputChange}
                rows="3"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              ></textarea>
            </div>

            {/* Meta Tag */}
            <div className="mb-4">
              <label
                htmlFor="metaTag"
                className="block text-sm font-medium text-gray-700"
              >
                Meta Tag
              </label>
              <input
                type="text"
                id="metaTag"
                name="metaTag"
                value={formData.metaTag}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Slug */}
            <div className="mb-4">
              <label
                htmlFor="slug"
                className="block text-sm font-medium text-gray-700"
              >
                Slug
              </label>
              <input
                type="text"
                id="slug"
                name="slug"
                value={formData.slug}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Image Upload */}
            <div className="mb-4">
              <label
                htmlFor="image"
                className="block text-sm font-medium text-gray-700"
              >
                Cover Image Upload
              </label>
              <input
                type="file"
                onChange={handleCoverImageChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* JSON Content */}
            <div className="mb-4">
              <label
                htmlFor="jsonContent"
                className="block text-sm font-medium text-gray-700"
              >
                JSON Content
              </label>
              <textarea
                id="jsonContent"
                name="jsonContent"
                value={formData.jsonContent}
                onChange={handleInputChange}
                rows="5"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              ></textarea>
            </div>

            <div className="mb-4">
              <button
                type="button"
                className="bg-indigo-500 text-white px-4 py-2 rounded"
                onClick={showAssetImageUploadModal}
              >
                Upload Asset Image
              </button>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Editor Content
              </label>
              <JoditEditor
                ref={editor}
                value={editorContent}
                onChange={(newContent) => contentFieldChanged(newContent)}
              />
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default AddBlogForm;
