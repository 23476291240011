import React, { useEffect, useState } from "react";
import AddArt from "./AddArt";
import axios from "../../axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function ArtView(props) {
  const { artId } = props.data;
  const API = process.env.REACT_APP_API_URL;
  const [closeForm, setCloseForm] = useState(false);
  const [viewResult, setViewResult] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (artId && API) {
      axios
        .get(`/art-lab/${artId}`)
        .then((response) => {
          if (!response.data.err) {
            
            setViewResult(response.data.result);
            setOpenView(true);
          }
        })
        .catch((err) => {
          console.error("Error fetching art content details:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [artId, API]);

  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  if (closeForm) {
    return <AddArt />;
  }

  return (
    <div className="p-4 bg-black text-white">
      <div className="bg-black p-6 rounded-lg shadow-lg text-white">
        <div className="flex justify-between items-center mb-4">
          <h4 className="text-xl font-semibold text-white">View ArtContent</h4>
          <button
            onClick={closeFormAndOpenPage}
            className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
            style={{
              backgroundImage:
                "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            Back
          </button>
        </div>

        {loading && <p>Loading...</p>}

        {!loading && openView && viewResult && (
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Service Type</h5>
                <p>{viewResult.serviceType}</p>
              </div>
              <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Title</h5>
                <p>{viewResult.title}</p>
              </div>
              <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Short Para</h5>
                <p>{viewResult.subPara}</p>
              </div>

              {viewResult.image && (
                <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                  <h5 className="text-lg font-semibold">Cover Image</h5>
                  <img
                    src={viewResult.image.url}
                    alt={`${viewResult.title} cover image`}
                    className="w-full h-full object-contain rounded"
                  />
                </div>
              )}

              <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Meta Title</h5>
                <p>{viewResult.metaTitle}</p>
              </div>
              <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Meta Description</h5>
                <p>{viewResult.metaDescription}</p>
              </div>

              <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Meta Tag</h5>
                <p>{viewResult.metaTags}</p>
              </div>
              {/* <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Date</h5>
                <p>{viewResult.createdAt ? viewResult.createdAt.split("T")[0] : ""}</p>
              </div> */}
              <div className="border p-4 rounded h-32 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Slug</h5>
                <p>{viewResult.slug}</p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="border p-4 rounded h-50 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">Rich Text Editor Left</h5>
                <div
                  className="prose text-white"
                  dangerouslySetInnerHTML={{ __html: viewResult.contentLeft }}
                />
              </div>
              <div className="border p-4 rounded h-50 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">
                  Rich Text Editor Right
                </h5>
                <div
                  className="prose text-white"
                  dangerouslySetInnerHTML={{ __html: viewResult.contentRight }}
                />
              </div>

              <div className="border p-4 rounded h-100 overflow-auto flex flex-col justify-center items-center text-center">
                <h5 className="text-lg font-semibold">JSON Content</h5>
                <textarea
                  placeholder="JSON Content"
                  value={JSON.stringify(viewResult.jsonContent, null, 2)}
                  rows="10"
                  readOnly
                  className="w-full mt-2 p-2 border border-gray-300 rounded bg-black text-white"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ArtView;
