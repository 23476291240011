import React, { useState } from "react";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import AddArt from "./AddArt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function AddContentForm() {
  const navigate = useNavigate();
  const [serviceType, setServiceType] = useState("");
  const [title, setTitle] = useState("");
  const [subPara, setSubPara] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaTags, setMetaTags] = useState("");
  const [slug, setSlug] = useState("");
  const [date, setDate] = useState("");
  const [err, setErr] = useState("");
  const [closeForm, setCloseForm] = useState(false);
  const [jsonContent, setJsonContent] = useState("");
  const [richTextContentLeft, setRichTextContentLeft] = useState("");
  const [richTextContentRight, setRichTextContentRight] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable

  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleRichTextChangeLeft = (content) => {
    setRichTextContentLeft(content);
  };
  const handleRichTextChangeRight = (content) => {
    setRichTextContentRight(content);
  };
  const API = process.env.REACT_APP_API_URL;

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button immediately

    const isCoverImageValid =
      coverImage instanceof File && coverImage.name.trim() !== "";

    if (
      title.trim() &&
      subPara.trim() &&
      isCoverImageValid &&
      metaDescription.trim() &&
      metaTitle.trim() &&
      metaTags.trim() &&
      slug.trim() &&
      // date.trim() &&
      jsonContent.trim() &&
      richTextContentLeft.trim() &&
      richTextContentRight.trim()
    ) {
      const formData = new FormData();

      formData.append("serviceType", serviceType);
      formData.append("title", title);
      formData.append("subPara", subPara);
      formData.append("image", coverImage);
      formData.append("metaDescription", metaDescription);
      formData.append("metaTitle", metaTitle);
      formData.append("metaTags", metaTags);
      formData.append("slug", slug.toLocaleLowerCase());
      // formData.append("createdAt", date);
      formData.append("jsonContent", jsonContent);
      formData.append("contentLeft", richTextContentLeft);
      formData.append("contentRight", richTextContentRight);

      axios
        .post(`${API}/art-lab`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (!response.data.err) {
            toast.success("Content added successfully");
            setCloseForm(true);
            navigate("/art-lab");
          } else {
            setErr(response.data.message);
            toast.error(response.data.message);
            setIsSubmitting(false); // Re-enable the button in case of error
          }
        })
        .catch((error) => {
          setErr("An error occurred");
          toast.error("An error occurred");
          setIsSubmitting(false); // Re-enable the button in case of error
        });
    } else {
      setErr("All fields are required");
      setIsSubmitting(false); // Re-enable the button if validation fails
    }
  };

  return (
    <div className="bg-black w-full h-full" style={{ height: "100vh" }}>
      <ToastContainer />
      {closeForm ? (
        <AddArt />
      ) : (
        <div className="bg-black w-full h-full ">
          <div className="w-full h-full mx-auto bg-black p-8 rounded-md shadow-md">
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-semibold text-white">Add Content</h4>
              <button
                onClick={closeFormAndOpenPage}
                className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
                Back
              </button>
            </div>
            <div className="flex space-x-8">
              <div className="flex-1">
                <div className="mb-4">
                  <p className="text-red-500">{err}</p>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="mb-4">
                      <label
                        htmlFor="service-type"
                        className="block text-sm font-medium text-white"
                      >
                        Service Type
                      </label>
                      <select
                        id="service-type"
                        value={serviceType}
                        onChange={(event) => setServiceType(event.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value=""></option>
                        <option value="Touchscreen Solutions">
                          Touchscreen Solutions
                        </option>

                        <option value="AR/VR Solutions">AR/VR Solutions</option>
                        <option value="Robotic Solutions">
                          Robotic Solutions
                        </option>
                        <option value="Holographic Solutions">
                          Holographic Solutions
                        </option>
                        <option value="Photobooths">Photobooths</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <p className="block text-sm font-medium text-white">
                        Title{" "}
                      </p>

                      <input
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm custom-placeholder"
                      />
                    </div>
                    <div className="mb-4">
                      <p className="block text-sm font-medium text-white">
                        Short Paragraph{" "}
                      </p>

                      <input
                        type="text"
                        placeholder="ShortPara"
                        value={subPara}
                        onChange={(e) => setSubPara(e.target.value)}
                        required
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <p className="block text-sm font-medium text-white">
                        Cover Image{" "}
                      </p>
                      <input
                        type="file"
                        onChange={handleCoverImageChange}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <p className="block text-sm font-medium text-white">
                        JSON Content{" "}
                      </p>

                      <textarea
                        placeholder="JSON Content"
                        value={jsonContent}
                        onChange={(e) => setJsonContent(e.target.value)}
                        rows="10"
                        required
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <p className="block text-sm font-medium text-white">
                        Rich Text Content Left
                      </p>
                      <ReactQuill
                        modules={modules}
                        onChange={handleRichTextChangeLeft}
                        value={richTextContentLeft}
                        theme="snow"
                        placeholder="Write something..."
                        className="border bg-white border-gray-300 rounded-md"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="flex-1">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <p className="block text-sm font-medium text-white">
                    Meta Content{" "}
                  </p>

                  <div className="mb-4">
                    <p className="block text-sm font-medium text-white">
                      Meta Title{" "}
                    </p>
                    <input
                      type="text"
                      placeholder="Meta Title"
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                      required
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <p className="block text-sm font-medium text-white">
                      Meta Description
                    </p>

                    <input
                      type="text"
                      placeholder="Meta Description"
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                      required
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <p className="block text-sm font-medium text-white">
                      Meta Tags{" "}
                    </p>

                    <input
                      type="text"
                      placeholder="Meta Tags"
                      value={metaTags}
                      onChange={(e) => setMetaTags(e.target.value)}
                      required
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <p className="block text-sm font-medium text-white">
                      Slug{" "}
                    </p>

                    <input
                      type="text"
                      placeholder="Slug"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      required
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  {/* <div className="mb-4">
                    <p className="block text-sm font-medium text-white">
                      Date{" "}
                    </p>

                    <input
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      required
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div> */}
                  <div className="mb-4">
                    <p className="block text-sm font-medium text-white">
                      Rich Text Content Right
                    </p>
                    <ReactQuill
                      modules={modules}
                      onChange={handleRichTextChangeRight}
                      value={richTextContentRight}
                      theme="snow"
                      placeholder="Write something..."
                      className="border bg-white border-gray-300 rounded-md "
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-700"
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
                    }}
                    disabled={isSubmitting} // Disable button when submitting
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddContentForm;
