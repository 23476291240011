import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddArt from "./AddArt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function EditArt(props) {
  const navigate = useNavigate();
  const [closeForm, setCloseForm] = useState(false);
  const [serviceType, setServiceType] = useState(
    props.data.editResult.serviceType
  );
  const [title, setTitle] = useState(props.data.editResult.title);
  const [subPara, setSubPara] = useState(props.data.editResult.subPara);
  const [coverImage, setCoverImage] = useState(
    props.data.editResult.image.url
  );
  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [metaTitle, setMetaTitle] = useState(props.data.editResult.metaTitle);
  const [metaTags, setMetaTags] = useState(props.data.editResult.metaTags);
  const [metaDescription, setMetaDescription] = useState(
    props.data.editResult.metaDescription
  );
  const [slug, setSlug] = useState(props.data.editResult.slug);
  const initialDate = props.data.editResult.createdAt;
  const formattedDate = initialDate ? initialDate.split("T")[0] : "";
  // const [date, setDate] = useState(formattedDate);
  const [jsonContent, setJsonContent] = useState(
    JSON.stringify(props.data.editResult.jsonContent, null, 2)
  );
  const [richTextContentLeft, setRichTextContentLeft] = useState(
    props.data.editResult.contentLeft
  );
  const [richTextContentRight, setRichTextContentRight] = useState(
    props.data.editResult.contentRight
  );

  const [err, setErr] = useState("");

  const Id = props.data.editResult._id;

  useEffect(() => {
    if (coverImage) {
      if (coverImage instanceof File) {
        // If coverImage is a File object, create a URL
        const objectUrl = URL.createObjectURL(coverImage);
        setCoverImageUrl(objectUrl);

        // Clean up the URL object when component unmounts or coverImage changes
        return () => URL.revokeObjectURL(objectUrl);
      } else {
        // If coverImage is a URL or something else, use it directly
        setCoverImageUrl(coverImage);
      }
    } else {
      // If no coverImage, clear the URL
      setCoverImageUrl(null);
    }
  }, [coverImage]);
  const handleRichTextChangeLeft = (contentLeft) => {
    setRichTextContentLeft(contentLeft);
  };

  const handleRichTextChangeRight = (contentRight) => {
    setRichTextContentRight(contentRight);
  };

  const API = process.env.REACT_APP_API_URL;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      title.trim() &&
      subPara.trim() &&
      metaDescription.trim() &&
      metaTitle.trim() &&
      metaTags.trim() &&
      slug.trim() &&
      // date.trim() &&
      jsonContent.trim() &&
      richTextContentLeft.trim() &&
      richTextContentRight.trim()
    ) {
      const formData = new FormData();
      formData.append("serviceType", serviceType);
      formData.append("title", title);
      formData.append("subPara", subPara);
      if (coverImage) {
        formData.append("image", coverImage);
      }
      formData.append("metaDescription", metaDescription);
      formData.append("metaTitle", metaTitle);
      formData.append("metaTags", metaTags);
      formData.append("slug", slug.toLocaleLowerCase());
      // formData.append("createdAt", date);
      formData.append("jsonContent", jsonContent);
      formData.append("contentLeft", richTextContentLeft);
      formData.append("contentRight", richTextContentRight);

      axios
        .patch(`/art-lab/${Id}`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (!response.data.err) {
            toast.success("Art edited successfully");
            setCloseForm(true);
            navigate("/art-lab");
          } else {
            setErr(response.data.message);
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error editing art:", error);
          setErr("An error occurred while editing art");
          toast.error("An error occurred while editing art");
        });
    } else {
      setErr("All fields are required");
    }
  };

  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImage(file);
    }
  };

  const handleRemoveCoverImage = () => {
    setCoverImage(null);
    setCoverImageUrl(null);
  };

  return (
    <>
      <ToastContainer />

      {closeForm ? (
        <AddArt />
      ) : (
        <div className="bg-black min-h-screen py-6 px-4 sm:px-6 lg:px-8">
          <h4 className="text-lg font-semibold text-white">Edit ArtContent</h4>
          <button
            onClick={closeFormAndOpenPage}
            className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
            style={{
              backgroundImage:
                "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            Back
          </button>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <p className="text-red-500">{err}</p>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="mb-4">
                  <label
                    htmlFor="service-type"
                    className="block text-sm font-medium text-white"
                  >
                    Service Type
                  </label>
                  <select
                    id="service-type"
                    value={serviceType}
                    onChange={(event) => setServiceType(event.target.value)}
                    className="mt-1 block w-full px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value=""></option>
                    <option value="Touchscreen Solutions">
                      Touchscreen Solutions
                    </option>
                    <option value="AR/VR Solutions">AR/VR Solutions</option>
                    <option value="Robotic Solutions">Robotic Solutions</option>
                    <option value="Holographic Solutions">
                      Holographic Solutions
                    </option>
                    <option value="Photobooths">Photobooths</option>
                  </select>
                </div>
                <div className="mb-4">
                  <p className="block text-sm font-medium text-white">Title </p>

                  <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="w-full px-3 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <p className="block text-sm font-medium text-white">
                    Sub Paragraph{" "}
                  </p>

                  <input
                    type="text"
                    placeholder="sub Paragraph"
                    value={subPara}
                    onChange={(e) => setSubPara(e.target.value)}
                    required
                    className="w-full px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-white text-sm font-medium">
                    Cover Image
                  </label>
                  {coverImageUrl ? (
                    <div>
                      <img
                        src={coverImageUrl}
                        alt="Cover Preview"
                        className="w-32 h-32 object-cover"
                      />
                      <button
                        type="button"
                        onClick={handleRemoveCoverImage}
                        className="mt-2 bg-red-500 text-white px-2 py-1 rounded"
                      >
                        Remove Image
                      </button>
                    </div>
                  ) : (
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleCoverImageChange}
                      className="mt-2"
                    />
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-white">
                    Rich Text Content Left
                  </label>
                  <ReactQuill
                    modules={modules}
                    onChange={handleRichTextChangeLeft}
                    value={richTextContentLeft || ""}
                    theme="snow"
                    placeholder="Write something..."
                    style={{ color: "white" }}
                  />
                </div>
              </form>
            </div>
            <div>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="mb-4">
                  <p className="block text-sm font-medium text-white">
                    Meta Title{" "}
                  </p>

                  <input
                    type="text"
                    placeholder="Meta Title"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                    className="w-full px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mb-2"
                  />
                  <p className="block text-sm font-medium text-white">
                    Meta Description{" "}
                  </p>

                  <input
                    type="text"
                    placeholder="Meta Description"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    className="w-full px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mb-2"
                  />
                  <p className="block text-sm font-medium text-white">
                    Meta Tag{" "}
                  </p>

                  <input
                    type="text"
                    placeholder="Meta Tags"
                    value={metaTags}
                    onChange={(e) => setMetaTags(e.target.value)}
                    className="w-full px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mb-2"
                  />
                  <p className="block text-sm font-medium text-white">Slug </p>

                  <input
                    type="text"
                    placeholder="Slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    className="w-full px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mb-2"
                  />
                  {/* <p className="block text-sm font-medium text-white">Date </p>

                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="w-full px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mb-4"
                  /> */}
                  <div className="mb-4">
                    <p className="block text-sm font-medium text-white">
                      JSON Content{" "}
                    </p>

                    <textarea
                      placeholder="JSON Content"
                      value={jsonContent}
                      onChange={(e) => setJsonContent(e.target.value)}
                      rows="20"
                      className="w-full h-30 px-3 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    ></textarea>
                  </div>
                  <label className="block text-sm font-medium text-white">
                    Rich Text Content Right
                  </label>
                  <ReactQuill
                    modules={modules}
                    onChange={handleRichTextChangeRight}
                    value={richTextContentRight || ""}
                    theme="snow"
                    placeholder="Write something..."
                    style={{ color: "white" }}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-300 text-white px-6 py-2 rounded"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default EditArt;
