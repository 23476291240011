import React from "react";
import { useNavigate } from "react-router-dom";
import "./AdminDashboard.css";

function AdminDashboard() {
  const navigate = useNavigate();

  const cardData = [
    {
      title: "Art Lab Management",
      path: "/art-lab",
    },
    {
      title: "Blog Management",
      path: "/blog-management",
    },
    {
      title: "Careers",
      // path: "/applications",
    },
  ];

  return (
    <div className="parentdashboardmainsection">
      <div className="bg-black min-h-screen flex items-center justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {cardData.map((card, index) => (
            <div key={index} className="parentdashboardbuttons">
              <div
                onClick={() => navigate(card.path)}
                className="parentdashboardchild"
              >
                <h1 className="btndashboardcardtitle">{card.title}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
