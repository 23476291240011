import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  // validateStatus: function (status) {
  //   return status < 201;
  // },
});

//  Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("adminToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default instance;
