import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import AddBlog from "./AddBlog";
import AssetImageUpload from "./AssetImageUpload";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"; // Import the wrapper
function EditBlog({ data }) {
  const MySwal = withReactContent(Swal); // Create a Swal instance with ReactContent
  const { blogId } = data;
  const API = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [closeForm, setCloseForm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    shortTitle: "",
    metaTitle: "",
    metaDescription: "",
    slug: "",
    image: "",
    jsonContent: "",
    editorContent: "",
  });

  const editor = useRef(null);

  useEffect(() => {
    if (blogId && API) {
      axios
        .get(`${API}/blog/${blogId}`)
        .then((response) => {
          if (!response.data.err) {
            const result = response.data.result;
            setBlogData(result);
            setFormData({
              title: result.title,
              shortTitle: result.shortTitle,
              metaTitle: result.metaTitle,
              metaDescription: result.metaDescription,
              slug: result.slug,
              image: result.image.url,
              jsonContent: JSON.stringify(result.jsonContent),
              editorContent: result.editorContent,
            });
            setCoverImageUrl(result.image.url);
          } else {
            toast.error("Error loading blog content.");
          }
        })
        .catch((err) => {
          console.error("Error fetching blog content details:", err);
          toast.error("An error occurred while fetching the blog content.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [blogId, API]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImage(file);
      setCoverImageUrl(URL.createObjectURL(file));
    }
  };

  const handleRemoveCoverImage = () => {
    setCoverImage(null);
    setCoverImageUrl(null);
    setFormData({
      ...formData,
      image: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();
    for (const key in formData) {
      updatedFormData.append(key, formData[key]);
    }
    if (coverImage) {
      updatedFormData.append("image", coverImage);
    }

    axios
      .patch(`${API}/blog/${blogId}`, updatedFormData)
      .then((response) => {
        if (!response.data.err) {
          toast.success("Blog updated successfully!");
          setCloseForm(true);
          navigate("/blog-management");
        } else {
          toast.error("Error updating blog.");
        }
      })
      .catch((err) => {
        console.error("Error updating blog:", err);
        toast.error("An error occurred while updating the blog.");
      });
  };

  const contentFieldChanged = (newContent) => {
    setFormData({
      ...formData,
      editorContent: newContent,
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }
  const handleAssetImageUpload = (imageUrl) => {
    // Handle the uploaded image URL in the parent component
    console.log("Received image URL:", imageUrl);
    // You can set it in the form state or do something else with it
  };
  const showAssetImageUploadModal = () => {
    MySwal.fire({
      title: "Upload Asset Image",
      html: <AssetImageUpload onUpload={handleAssetImageUpload} />,
      showConfirmButton: false, // You can add a confirm button if needed
      showCloseButton: true,
      allowOutsideClick: false,
    });
  };
  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  if (closeForm) {
    return <AddBlog />;
  }
  return (
    <>
      <ToastContainer />
      {closeForm ? (
        <AddBlog />
      ) : (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6">Edit Blog</h2>
          <div className="flex justify-end">
            <button
              onClick={closeFormAndOpenPage}
              className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span className="ml-2">Back</span>
            </button>
          </div>

          {blogData ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="shortTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Short Title
                </label>
                <input
                  type="text"
                  id="shortTitle"
                  name="shortTitle"
                  value={formData.shortTitle}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="metaTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Title
                </label>
                <input
                  type="text"
                  id="metaTitle"
                  name="metaTitle"
                  value={formData.metaTitle}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="metaDescription"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Description
                </label>
                <textarea
                  id="metaDescription"
                  name="metaDescription"
                  value={formData.metaDescription}
                  onChange={handleChange}
                  rows="3"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="slug"
                  className="block text-sm font-medium text-gray-700"
                >
                  Slug
                </label>
                <textarea
                  id="slug"
                  name="slug"
                  value={formData.slug}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cover Image
                </label>
                <div className="mt-2">
                  {coverImageUrl ? (
                    <>
                      <img
                        src={coverImageUrl}
                        alt="Cover Image Preview"
                        className="w-full h-auto border border-gray-300 rounded-md"
                      />
                      <button
                        type="button"
                        onClick={handleRemoveCoverImage}
                        className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Remove Image
                      </button>
                    </>
                  ) : (
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleCoverImageChange}
                      className="mt-2"
                    />
                  )}
                </div>
              </div>

              <div className="mb-4">
                <button
                  type="button"
                  className="bg-indigo-500 text-white px-4 py-2 rounded"
                  onClick={showAssetImageUploadModal}
                >
                  Upload Asset Image
                </button>
              </div>

              <div>
                <label
                  htmlFor="editorContent"
                  className="block text-sm font-medium text-gray-700"
                >
                  Editor Content
                </label>
                <JoditEditor
                  ref={editor}
                  value={formData.editorContent}
                  onChange={contentFieldChanged}
                />
              </div>
              <div>
                <label
                  htmlFor="jsonContent"
                  className="block text-sm font-medium text-gray-700"
                >
                  JSON Content
                </label>
                <textarea
                  id="jsonContent"
                  name="jsonContent"
                  value={formData.jsonContent}
                  onChange={handleChange}
                  rows="20"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Update Blog
                </button>
              </div>
            </form>
          ) : (
            <p>No blog data available</p>
          )}
        </div>
      )}
    </>
  );
}

export default EditBlog;
